export const testEvents = [
  {
    created_at: '2024-04-26T19:47:50.413155+03:00',
    data: {
      method: 'controller.vehicle_plate_event',
      photo: null,
      photo_stay: null,
      replace: null,
      stay_id: '2f9d0150d58a472e8f809251024adaca',
      vehicle_plate: '',
      vehicle_plate_stay: 'E333EE333',
    },
    id: 'd235eb08aa6c4a5eaec380a235d4efa6',
    message: 'ГРЗ на выезде не совпадает с ГРЗ на въезде',
    parking_id: '000',
    source: 'lane-2-1',
    source_name: 'Стойка 04',
    source_type: 'rack.lane',
    status: 'active',
    timestamp: '2024-04-26T19:47:49.999120+03:00',
    type: 'notification',
    updated_at: '2024-04-26T19:47:50.413184+03:00',
  },
  {
    created_at: '2024-04-26T19:42:02.255704+03:00',
    data: {
      method: 'controller.vehicle_plate_event',
      photo: null,
      replace: null,
      stay_id: null,
      vehicle_plate: 'E333EE333',
      vehicle_plate_mean: 'C518CE89',
    },
    id: '3654b7ccad1f4e93b7832b3c49301273',
    message: 'Распознанный ГРЗ не совпадает с зарегистрированным E333EE333',
    parking_id: '000',
    source: 'lane-1-1',
    source_name: 'Стойка 01',
    source_type: 'rack.lane',
    status: 'active',
    timestamp: '2024-04-26T19:42:01.432829+03:00',
    type: 'notification',
    updated_at: '2024-04-26T19:42:02.255725+03:00',
  },
  {
    created_at: '2022-11-13T19:07:13.624606+03:00',
    data: {
      method: 'controller.vehicle_plate_event',
      replace: null,
    },
    id: 'b38cc712-7d5b-11ed-b610-0e8b1da2deaa',
    message: 'ГРЗ отличается от недавно выехавшего',
    parking_id: '000',
    source: 'lane-1-1',
    source_name: 'Стойка 01',
    source_type: 'rack.lane',
    status: 'active',
    timestamp: '2022-12-16T19:07:11.621234+03:00',
    type: 'notification',
    updated_at: '2022-12-16T19:07:12.624616+03:00',
  },
  {
    created_at: '2022-11-13T19:07:14.624606+03:00',
    data: {
      method: 'controller.vehicle_plate_event',
      replace: null,
      stay_id: '0018ad1c-7d57-11ed-aed5-0e8b1da2deaa',
    },
    id: 'b38cc712-7d5b-11ed-b610-0e8b1da2deaa',
    message: 'ГРЗ отличается от недавно выехавшего на 1 символ',
    parking_id: '000',
    source: 'lane-1-5',
    source_name: 'Стойка 05',
    source_type: 'rack.lane',
    status: 'active',
    timestamp: '2022-12-16T19:07:11.621234+03:00',
    type: 'notification',
    updated_at: '2022-12-16T19:07:12.624616+03:00',
  },
];

export const nuNotRecognisedEvent = [
  {
    created_at: '2022-12-16T19:07:12.624606+03:00',
    data: {
      method: 'controller.vehicle_plate_event',
      stay_id: 'f2fa36ee899347c29fa9d7ac50678dd9',
      vehicle_plate: 'A000AA0',
      photo: 'c4a5a089925d577c5bec7776cd29fc5bbd1d36eb',
      vehicle_plate_mean: 'A999AB1', // from SO
      photo_stay: 'c4a5a089925d577c5bec7776cd29fc5bbd1d36eb', // from STAY
    },
    id: 'b38cc712-7d5b-11ed-b610-0e8b1da2deaa',
    message: 'Распознанный ГРЗ не совпадает с зарегистрированным A999AB1',
    parking_id: '000',
    source: 'lane-1-3',
    source_name: 'Стойка 03',
    source_type: 'rack.lane',
    status: 'active',
    timestamp: '2022-12-16T19:07:11.621234+03:00',
    type: 'notification',
    updated_at: '2022-12-16T19:07:12.624616+03:00',
  },
  // {
  //   id: '7ecd1702-e421-11ed-b5f5-005056bdcc9a',
  //   parking_id: '000',
  //   type: 'notification',
  //   timestamp: '2023-04-26T16:00:03.720593+05:00',
  //   source: 'lane-2-2',
  //   source_name: 'Стойка 05',
  //   source_type: 'rack.lane',
  //   status: 'active',
  //   message: 'ГРЗ на выезде не совпадает с ГРЗ на въезде',
  //   data: {
  //     method: 'controller.vehicle_plate_event',
  //     replace: null,
  //     stay_id: 'a846640a-e429-11ed-a44d-005056bdcc9a', //'bc5a64fa-e41f-11ed-a44d-005056bdcc9a',
  //   },
  //   created_at: '2023-04-26T16:00:03.081745+05:00',
  //   updated_at: '2023-04-26T16:00:05.929829+05:00',
  // },
  // {
  //   id: '7ecd1702-e421-11ed-b5f5-005056bdcc9b',
  //   parking_id: '000',
  //   type: 'notification',
  //   timestamp: '2023-04-26T16:00:06.720593+05:00',
  //   source: 'lane-1-1',
  //   source_name: 'Стойка 01',
  //   source_type: 'rack.lane',
  //   status: 'active',
  //   message: 'ГРЗ на выезде не совпадает с ГРЗ на въезде',
  //   data: {
  //     method: 'controller.vehicle_plate_event',
  //     replace: null,
  //     stay_id: 'a846640a-e429-11ed-a44d-005056bdcc9a', //'bc5a64fa-e41f-11ed-a44d-005056bdcc9a',
  //   },
  //   created_at: '2023-04-26T15:00:06.081745+05:00',
  //   updated_at: '2023-04-26T16:00:09.929829+05:00',
  // },
];

export const nuGreyListEvent = [
  {
    id: '7ecd1702-e421-11ed-b5f5-005056bdcc9f',
    parking_id: '000',
    type: 'notification',
    timestamp: '2023-04-26T16:00:03.720593+05:00',
    source: 'lane-2-2',
    source_name: 'Стойка 05',
    source_type: 'rack.lane',
    status: 'active',
    message:
      'Проезд с идификатором из серого списка через стойку {passage.rack_id}, въезд в парковочную зону {rack.data.get("zone_to")} Идентификатор: ГРЗ {vehicle_plate} Причина внесения {grey_plate.reason} {datetime.now()}',
    data: {
      method: 'controller.event',
      replace: null,
    },
    created_at: '2023-04-26T16:00:03.081745+05:00',
    updated_at: '2023-04-26T16:00:05.929829+05:00',
  },
];
