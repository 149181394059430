import moment from 'moment';

import { config } from 'config';
import Event from './classes/Event';
import { socket } from 'api';
import { testEvents, nuNotRecognisedEvent, nuGreyListEvent } from 'mocks';
import { notify } from 'libs/common';
import { user } from 'services/user';

export class AppSocket {
  props;

  constructor(props) {
    this.props = props;

    // socket
    window.socket = socket.init();
    this.initSocketEvents();

    //console.log('🚀 ~ window.api_v2 pre');

    // start
    socket.call('dictionaries.list');
    socket.call('get_zones_status');
    socket.call('get_active_events');
    socket.call('get_all_calls');
  }

  update(calls, unrecognized) {
    this.props.calls = calls;
    this.props.unrecognized = unrecognized;
  }

  /**
   * 1. window.dict
   * 2. window.user
   * 3. setEvent, setCallActive, addWidget, removeWidget, openWindow, setCall, dropCall, updateStatus, updateStatusBg, setEventsList, setBoardsData, setUnrecognized, calls, unrecognized
   * 4. window.haveRight
   * 5. isActiveCall getActiveCallsCnt
   * 6.
   */
  initSocketEvents = () => {
    const self = this;

    /**
     * get adds dictionaries
     */
    window.socket.on('dictionaries.list', (msg) => {
      // find
      const dict = msg.find((value) => {
        return value.id === 'reasonofpass';
      });

      if (dict) {
        window.dict['reasonofpass'] = dict.details;
      }
    });

    /**
     * logout on error
     */
    window.socket.on('user_error', (__msg) => {
      user.logoutUser();
    });

    /**
     * error ?
     */
    window.socket.on('error', (msg) => {
      //console.log('🚀 ~ backend error', msg);
      notify('Ошибка', msg);
    });

    /**
     * set signals
     */
    window.socket.on('signals.set', (msg) => {
      self.props.setEvent(new Event(msg, true));
    });

    /**
     * call answer
     * broadcast
     */
    window.socket.on('calls.answer', (msg) => {
      const rightCalls = window.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }
      //console.log('🚀 ~ calls.answer', msg);
      self.props.setCallActive(msg);
      self.props.addWidget('activeCall');
    });

    /**
     * call end
     * broadcast
     */
    window.socket.on('calls.end', (msg) => {
      const rightCalls = window.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }

      const id = msg;
      //console.log('🚀 ~ calls.end', msg);

      const current = window.isActiveCall(id);
      const all = window.getActiveCallsCnt();
      self.props.dropCall(id);
      if (!current) {
        return;
      }

      // test for hide
      let d1 = current ? 1 : 0;
      const needHide = all - d1 === 0;
      if (needHide) {
        self.props.removeWidget('activeCall');
      }
    });

    /**
     * calls failed
     */
    window.socket.on('calls.failed', () => {
      const rightCalls = window.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }

      self.props.openWindow({
        id: 'call_error',
        type: 'call_error',
        data: {},
        header: '',
      });
    });

    /**
     * calls call
     */
    window.socket.on('calls.call', (msg) => {
      const rightCalls = window.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }
      //console.log('🚀 ~ calls.call', msg);
      self.props.setCall(msg);
    });

    /**
     * get all cals from backend
     */
    window.socket.on('get_all_calls', (msg) => {
      const rightCalls = window.haveRight('mnemo.calls');
      if (!rightCalls) {
        return;
      }

      //console.log('🚀 ~ get_all_calls', msg);
      const map1 = {};
      if (!msg.calls.length) {
        //window.clearActiveCalls();
      }

      msg.calls.forEach((el) => {
        map1[el.source_inner_id] = el;
      });

      for (let key in self.props.calls) {
        if (!map1[key]) {
          //console.log('🚀 ~ get_all_calls: drop call', key);
          self.props.dropCall(key);
          //window.removeActiveCall(key);
        }
      }

      msg.calls.forEach((el) => {
        //console.log('🚀 ~ get_all_calls: set call', el);
        self.props.setCall(el);
      });
    });

    /**
     * get rack status from backend
     */
    window.socket.on('get_state', (msg) => {
      self.props.updateStatus({ source: msg.target, params: msg });
    });

    /**
     * get rack status from backend
     */
    window.socket.on('get_state_background', (msg) => {
      self.props.updateStatusBg({ source: msg.target, params: msg });
    });

    /**
     * get active events from backend
     */
    window.socket.on('get_active_events', (data) => {
      // console.log('🚀 socket <- get_active_events', this.formatEvents(data.events));
      const test = config.testEvents;
      const messages = test ? (test === 'unrecognized' ? nuNotRecognisedEvent : nuGreyListEvent) : data.events;
      const events = {};
      const unrecognized = [];
      for (let key in messages) {
        const msg = messages[key];
        if (msg) {
          if (!events[msg.source]) {
            events[msg.source] = {};
          }
          events[msg.source][msg.id] = new Event(msg, true);
          if (this.isUnrecognized(msg)) {
            unrecognized.push(msg);
          } else {
            this.checkGreyList(msg);
          }
        }
      }
      //console.log(events)
      self.props.setEventsList(events);
      self.setUnrecognized(unrecognized);
    });

    /**
     * get event from backend
     */
    window.socket.on('add_event', (data) => {
      if (data.status !== 'processed') {
        // console.log('🚀 socket <- add_event', this.formatEvent(data));
        self.props.setEvent(new Event(data, true));

        //controller.plate_not_match
        if (this.isUnrecognized(data)) {
          //unrecognized = data;
          const current = this.props.unrecognized;
          if (!current || !current.created_at) {
            self.setUnrecognized([data]);
          }
        }
      } else {
        this.checkGreyList(data);
      }
    });

    /**
     * get zone status from backend
     */
    window.socket.on('get_zones_status', (msg) => {
      self.props.setBoardsData(msg.zones);
    });
  };

  formatEvents = (events) => {
    return events.map((el) => {
      return this.formatEvent(el);
    });
  };

  formatEvent = (el) => {
    const data = el.data ? el.data : {};
    return { source: el.source, message: el.message, method: data.method, stay_id: data.stay_id, other: el };
  };

  /**
   * set urecognized
   * @param {*} items
   * @returns
   */
  setUnrecognized = (items) => {
    if (!items.length) {
      this.props.setUnrecognized(null);
      return;
    }

    // find old one
    if (items.length > 1) {
      items.sort((a, b) => {
        const d1 = moment(a.created_at);
        const d2 = moment(b.created_at);
        if (d1 === d2) {
          return 0;
        }
        const r = d1 - d2;
        //console.log('🚀 ~ items.sort ~ r', d1, d2, r);
        return r;
      });
    }

    // console.log('🚀 socket <- setUnrecognized', items[0]);
    this.props.setUnrecognized(items[0]);

    //console.log('🚀 ~ items.sort ~ items', items);
  };

  /**
   * is unrecognized
   * @param {*} data
   * @returns
   */
  isUnrecognized = (data) => {
    if (!window.haveRight('mnemo.allow_payment_mean') || !window.haveRight('mnemo.confirm_events')) {
      return false;
    }
    const test = data.data ? data.data.method : '';
    const isNotification = data.type === 'notification';

    if ((test === 'controller.vehicle_plate_event' || test === 'controller.vehicle_plate_secondtry') && isNotification) {
      //console.log('🚀 ~ unrecognized', data);
      //this.props.setUnrecognized(data);
      return true;
    }
    return false;
  };

  /**
   * check for grey list
   * @param {*} data
   */
  checkGreyList = (data) => {
    // const test = data.message ?? '';
    const test = data.data ? data.data.method : '';
    if (test === 'gray_plate' || test === 'grey_plate') {
      notify('ГРЗ из серого списка', data.message, 'info', 2000, 'bottom-right', true);
    }
  };
}
