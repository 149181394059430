import { SET_EVENT, DEACTIVATE_EVENT, SET_EVENTS_LIST, DEACTIVATE_ALL_EVENTS, SET_EVENT_UNRECOGNIZED } from '../constants';

const eventsReducer = (state, action) => {
  const defaultState = {
    events: {},
    unrecognized: null,
  };
  if (!state) {
    state = defaultState;
  }

  let newEvents = { ...state.events };

  switch (action.type) {
    case SET_EVENT:
      if (!newEvents[action.event.source]) {
        newEvents[action.event.source] = {};
      }
      newEvents[action.event.source][action.event.id] = action.event;
      return {
        ...state,
        events: newEvents,
      };

    case SET_EVENT_UNRECOGNIZED:
      return {
        ...state,
        unrecognized: action.event,
      };

    case DEACTIVATE_EVENT:
      delete newEvents[action.rackId][action.eventId];
      return {
        ...state,
        events: newEvents,
      };
    case DEACTIVATE_ALL_EVENTS:
      delete newEvents[action.rackId];
      return {
        ...state,
        events: newEvents,
      };
    case SET_EVENTS_LIST:
      return {
        ...state,
        events: action.data,
      };
    default:
      return state;
  }
};

export default eventsReducer;
