import config from 'config';

export const getStayData = (msg) => {
  if (!msg || !msg.passages) {
    return { photo: '-', plate: '-' };
  }
  const passageId = Object.keys(msg.passages)[0];
  const passage = msg.passages[passageId];

  // photo
  const photos = passage.data.photos;
  const photoId = Object.keys(photos)[0];
  const photo = photos[photoId];

  // plate
  const plate = passage.vehicle_plate;

  return { photo, plate };
};

export const getPhotoUrl = (value) => {
  if (!value || value === '-') {
    return '';
  }
  return config.network.images_url + value;
};

/**
 * get items with vp and photo
 * @param eventData
 * @param photo - photo_stay from event data / photo from stay
 * @param plate
 * @returns
 */
export const getItems = (nodeType, eventData, photo, plate) => {
  // console.log('🚀 ~ getItems ~ nodeType, eventData, photo, plate:', nodeType, eventData, photo, plate);
  const out = [];
  const stayId =  eventData?.passage_id; // eventData?.stay_id;
  // console.log('🚀 ~ getItems ~ stayId:', stayId);

  // in
  // check event type
  // type === in
  if (nodeType === 'entry') {
    // from event data
    if (eventData.photo || eventData.vehicle_plate) {
      out.push({
        photo: getPhotoUrl(eventData.photo),
        title: 'Въезд' + (eventData.vehicle_plate ? ' (' + eventData.vehicle_plate + ')' : ''),
        plate: eventData.vehicle_plate,
        refresh: true,
        stayId,
      });
    } else if ((photo && photo !== '-') || !!plate) {
      // from stay
      out.push({ photo: getPhotoUrl(photo), title: 'Въезд' + (plate ? ' (' + plate + ')' : ''), plate, refresh: true, stayId });
    }
    if (!out.length) {
      out.push({ photo: '-', title: 'Въезд' + (plate ? ' (' + plate + ')' : ''), plate, stayId });
    }
    return out;
  }

  // out
  // find in
  if ((photo && photo !== '-') || !!plate) {
    out.push({ photo: getPhotoUrl(photo), title: 'Въезд' + (plate ? ' (' + plate + ')' : ''), plate, refresh: true, stayId });
  }
  // find out
  // if (eventData.photo || eventData.vehicle_plate) {
  out.push({
    departure: true,
    photo: getPhotoUrl(eventData.photo),
    title: 'Выезд' + (eventData.vehicle_plate ? ' (' + eventData.vehicle_plate + ')' : ''),
    plate: eventData.vehicle_plate,
  });
  // }

  // if ((photo && photo !== '-') || plate) {
  //   const title = 'Въезд' + (plate ? ' (' + plate + ')' : '');
  //   out.push({ photo: getPhotoUrl(photo), title, plate: plate ?? '' });
  // }

  // if (eventData.photo || eventData.vehicle_plate) {
  //   out.push({ photo: getPhotoUrl(eventData.photo), title: 'Выезд (' + eventData.vehicle_plate + ')', plate: eventData.vehicle_plate ?? '' });
  // }

  // console.log('🚀 ~ out:', out);
  return out;
};
